<template>
  <div class="home" data-view>
    <div class="home__hero hero">
      <div class="container">
        <div class="hero__container">
          <div class="hero__descr">
            <h1 class="hero__title hero__title--main">
              Деньги для оборота
              <vue-typer
                :text="['быстро', 'надежно', 'в срок']"
                erase-style="backspace"
              />
            </h1>
            <div
              class="hero__text hero__text--main"
              v-animate-onscroll="'animated backIn'"
            >
              <p class="p-3">
                Факторинговый маркетплейс для поиска выгодных условий
                финансирования вашего бизнеса.
              </p>
            </div>
            <div
              class="hero__btn"
              v-animate-onscroll="'animated backIn delay-05'"
            >
              <el-button
                type="primary"
                size="medium"
                @click="$modal.show('contact')"
                >Отправить запрос</el-button
              >
            </div>
          </div>
          <div class="hero__img">
            <v-hero-img class="m-hide" />
            <div class="d-hide" v-animate-onscroll="'animated backIn'">
              <img src="@/assets/img/hero-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="hero__graph">
        <v-graph
          :scheme="{
            scatter: 0.25,
            angle: 20,
            offset: 0.0,
            background: {
              start: 'rgba(206, 229, 139, 0.2)',
              stop: 'rgba(206, 229, 139, 0)',
            },
            points: {
              count: 8,
              background: 'rgba(179, 221, 136, 0)',
              border: {
                width: 4,
                color: 'rgba(118, 200, 120, 0)',
              },
              radius: 6,
            },
            line: {
              width: 2,
              color: 'rgba(118, 200, 120, 0)',
            },
            animation: {
              duration: 500,
              delay: 2000,
            },
          }"
        />
        <v-graph
          :scheme="{
            scatter: 0.25,
            angle: 15,
            offset: 0.0,
            background: {
              start: 'rgba(118, 200, 120, 0.2)',
              stop: 'rgba(118, 200, 120, 0)',
            },
            points: {
              count: 8,
              background: 'rgba(179, 221, 136, 1)',
              border: {
                width: 4,
                color: 'rgba(118, 200, 120, 1)',
              },
              radius: 6,
            },
            line: {
              width: 2,
              color: 'rgba(118, 200, 120, 1)',
            },
            animation: {
              duration: 500,
              delay: 2000,
            },
          }"
        />
        <v-graph
          :scheme="{
            scatter: 0.25,
            angle: 10,
            offset: 0.0,
            background: {
              start: 'rgba(21, 164, 161, 0.2)',
              stop: 'rgba(21, 164, 161, 0)',
            },
            points: {
              count: 7,
              background: 'rgba(214, 244, 237, 0)',
              border: {
                width: 4,
                color: 'rgba(174, 231, 219, 0)',
              },
              radius: 6,
            },
            line: {
              width: 2,
              color: 'rgba(174, 231, 219, 0)',
            },
            animation: {
              duration: 500,
              delay: 2000,
            },
          }"
        />
      </div>
    </div>
    <div class="home__banner banner">
      <div class="container">
        <div class="banner__container">
          <div class="h1 banner__title" v-animate-onscroll="'animated backIn'">
            Поможем <span>получить</span> финансирова&shy;ние ваших
            неоплачен&shy;ных счетов
          </div>
          <div class="banner__wrapper">
            <div
              class="banner__col"
              v-animate-onscroll="'animated backIn delay-05'"
            >
              <h2 class="banner__subtitle">
                9&nbsp;лет на&nbsp;рынке факторинга
              </h2>
              <p class="banner__text p-3 reg">
                <span class="bold">EdiFin</span>&nbsp;&mdash; технологическая
                платформа от&nbsp;крупнейшего ЭДО-оператора Ediweb
                и&nbsp;разработчика FactorPlat.
              </p>
            </div>
            <div
              class="banner__col"
              v-animate-onscroll="'animated backIn delay-10'"
            >
              <h2 class="banner__subtitle">Прямой выход на&nbsp;факторов</h2>
              <p class="banner__text p-3 reg">
                Сотрудничаем с&nbsp;ключевыми факторинговыми компаниями.
                Передадим ваш запрос напрямую.
              </p>
            </div>
            <div
              class="banner__col"
              v-animate-onscroll="'animated backIn delay-15'"
            >
              <h2 class="banner__subtitle">
                <img src="@/assets/img/banner-logo.png" class="banner-logo" />
              </h2>
              <p class="banner__text p-3 reg">
                <span class="bold">Toп-100</span> финтех-компаний России в 2018
                по версии Deloitte
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home__carousel">
      <div class="container">
        <h2
          class="home__carousel-title"
          v-animate-onscroll="'animated backIn delay-05'"
        >
          Выбирайте подходящие условия
        </h2>
        <carousel
          v-animate-onscroll="'animated backIn'"
          loop
          navigationEnabled
          :paginationEnabled="false"
          :perPageCustom="[
            [320, 1],
            [710, 2],
            [1060, 3],
          ]"
          :navigationClickTargetSize="10"
          navigationNextLabel='<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 33L17 17L1 1" stroke="#AFC1B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
          navigationPrevLabel='<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 33L1 17L17 1" stroke="#AFC1B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
        >
          <slide>
            <div>
              <v-card
                class="card--middle card--3"
                @click.native="$modal.show('factoring-regressed')"
                :imgSrc="require('@/assets/img/cards/03.svg')"
              >
                <template v-slot:title>
                  Факторинг <br />
                  c&nbsp;регрессом
                </template>
                <template v-slot:text>
                  Финансирование до 100% <br />
                  До 180 дней <br />
                  Любые дебиторы
                </template>
              </v-card>
            </div>
          </slide>
          <slide>
            <div>
              <v-card
                class="card--middle card--2"
                @click.native="$modal.show('factoring-closed')"
                :imgSrc="require('@/assets/img/cards/02.svg')"
              >
                <template v-slot:title>
                  Закрытый <br />
                  факторинг
                </template>
                <template v-slot:text>
                  Финансирование до 90% <br />
                  До 120 дней <br />
                  Номинальный счёт
                </template>
              </v-card>
            </div>
          </slide>
          <slide>
            <div>
              <v-card
                class="card--middle card--1"
                @click.native="$modal.show('factoring-not-regressed')"
                :imgSrc="require('@/assets/img/cards/01.svg')"
              >
                <template v-slot:title> Факторинг <br />без регресса </template>
                <template v-slot:text>
                  Покупка до 100% <br />
                  До 120 дней<br />&nbsp;
                </template>
              </v-card>
            </div>
          </slide>
          <slide>
            <div>
              <v-card
                class="card--middle card--4"
                @click.native="$modal.show('factoring-reveresed')"
                :imgSrc="require('@/assets/img/cards/04.svg')"
              >
                <template v-slot:title>
                  Реверсивный <br />
                  факторинг
                </template>
                <template v-slot:text>
                  Финансирование до 100% <br />
                  До 120 дней <br />
                  Любые поставщики
                </template>
              </v-card>
            </div>
          </slide>
        </carousel>
        <div
          class="home__carousel-btn"
          v-animate-onscroll="'animated backIn delay-05'"
        >
          <router-link
            :to="{ name: 'Marketplace' }"
            class="el-button el-button--success is-plain"
            >Подробнее</router-link
          >
        </div>
      </div>
    </div>
    <div class="home__bill-anim">
      <div class="container">
        <!-- desktop -->
        <v-scroll-anim
          class="m-hide scroll-anim--reverse"
          style="height: 2000px"
          :config="config1"
        >
          <v-bill-to-form />
        </v-scroll-anim>

        <!-- mobile -->
        <div class="d-hide anim-mobile">
          <div
            class="anim-mobile__block"
            v-animate-onscroll="'animated backIn'"
          >
            <div class="anim-mobile__img">
              <img src="@/assets/img/scroll-anim/2/01.png" />
            </div>
            <div class="anim-mobile__text">
              <h2>Легко избавьтесь от кассового разрыва</h2>
              <p class="p-3 reg">
                Вам не нужно открывать новые кредитные линии, пользоваться
                овердрафтом в банке или подстраиваться под жёсткие требования
                крупных факторов. EdiFin обеспечит гибкий подход к оплате счетов
                от всех ваших дебиторов.
              </p>
            </div>
          </div>

          <div
            class="anim-mobile__block"
            v-animate-onscroll="'animated backIn'"
          >
            <div class="anim-mobile__img">
              <img src="@/assets/img/scroll-anim/2/02.png" />
            </div>
            <div class="anim-mobile__text">
              <h2>Отправьте запрос через EdiFin</h2>
              <p class="p-3 reg">
                Заполните форму на сайте. Мы мгновенно передадим заявку нашим
                партнёрам-факторам. Без дополнительных комиссий, длительных
                проверок и лишней бюрократии с бумагами.
              </p>
            </div>
          </div>

          <div
            class="anim-mobile__block"
            v-animate-onscroll="'animated backIn'"
          >
            <div class="anim-mobile__img">
              <img src="@/assets/img/scroll-anim/1/phone_msg.png" />
            </div>
            <div class="anim-mobile__text">
              <h2>Получите деньги</h2>
              <p class="p-3 reg">
                Получите предложение и&nbsp;заключите соглашение
                с&nbsp;фактором. Начинайте работать. Пополните оборотный капитал
                вашей компании за&nbsp;счёт переуступки дебиторской
                задолженности в&nbsp;короткие сроки.
              </p>
            </div>
          </div>
        </div>
        <!-- // mobile -->
      </div>
    </div>
    <div class="home__benefits benefits">
      <div class="container">
        <h2 class="benefits__title" v-animate-onscroll="'animated backIn'">
          Почему именно EdiFin
        </h2>
        <v-benefits />
      </div>
    </div>
    <div class="home__carousel">
      <div class="container">
        <h2
          class="home__carousel-title"
          v-animate-onscroll="'animated backIn delay-05'"
        >
          Мнение клиентов о&nbsp;работе с&nbsp;финансовыми сервисами Ediweb
        </h2>
        <carousel
          v-animate-onscroll="'animated backIn delay-05'"
          loop
          navigationEnabled
          :paginationEnabled="false"
          :perPageCustom="[
            [320, 1],
            [710, 2],
            [1060, 3],
          ]"
          :navigationClickTargetSize="10"
          navigationNextLabel='<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 33L17 17L1 1" stroke="#AFC1B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
          navigationPrevLabel='<svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 33L1 17L17 1" stroke="#AFC1B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
        >
          <slide>
            <div>
              <v-card-review>
                <template v-slot:text>
                  &laquo;В&nbsp;результате сотрудничества наша компания ушла
                  от&nbsp;проблемы кассового разрыва, обострившейся
                  во&nbsp;время пандемии. Мы&nbsp;получили возможность
                  направлять деньги на&nbsp;свои производственные
                  и&nbsp;операционные потребности&raquo;.</template
                >
                <template v-slot:author>
                  <span>Мамедов&nbsp;Р.&nbsp;Ф.</span>, директор ООО&nbsp;Инлайт
                  Групп
                </template>
              </v-card-review>
            </div>
          </slide>
          <slide>
            <div>
              <v-card-review>
                <template v-slot:text>
                  &laquo;Ранее мы&nbsp;испытывали дискомфорт с&nbsp;длительным
                  процессом пополнения оборотных средств и&nbsp;медленным
                  товарооборотом. Теперь кассовые разрывы ликвидированы.
                  Оборотный капитал пополнен. Все факторинговые сделки находятся
                  под контролем&raquo;.</template
                >
                <template v-slot:author>
                  <span>Абрамов&nbsp;Т.&nbsp;Д.</span>, генеральный директор
                  ООО&nbsp;Континент
                </template>
              </v-card-review>
            </div>
          </slide>
          <slide>
            <div>
              <v-card-review>
                <template v-slot:text>
                  &laquo;Мы&nbsp;ускорили процесс получения денежных средств
                  и&nbsp;сократили трудозатраты на&nbsp;ведение
                  документооборота. Стали направлять деньги на&nbsp;закрытие
                  своих потребностей, получая их&nbsp;максимально быстро после
                  поставки&raquo;.</template
                >
                <template v-slot:author>
                  <span>Пилипко&nbsp;О.&nbsp;Н.</span>, генеральный директор
                  ООО&nbsp;Оптимафуд
                </template>
              </v-card-review>
            </div>
          </slide>
          <slide>
            <div>
              <v-card-review>
                <template v-slot:text>
                  &laquo;Быстрое пополнение оборотных средств является насущной
                  необходимостью нашего предприятия. Вместо нескольких недель
                  мы&nbsp;стали получать оплату буквально в&nbsp;день поставки.
                  При этом, у&nbsp;нас появился контроль за&nbsp;состоянием
                  заявок&raquo;.</template
                >
                <template v-slot:author>
                  <span>Сабурова&nbsp;Л.&nbsp;В.</span>, генеральный директор
                  ООО&nbsp;Лидинг
                </template>
              </v-card-review>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
    <div class="home__form">
      <v-form-block />
    </div>

    <v-dialog name="contact">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/9968c31f'" />
    </v-dialog>

    <v-dialog name="factoring-not-regressed">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/742ecb4f'" />
    </v-dialog>

    <v-dialog name="factoring-closed">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/ea58d804'" />
    </v-dialog>

    <v-dialog name="factoring-regressed">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/2ab23d80'" />
    </v-dialog>

    <v-dialog name="factoring-reveresed">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/5a4da2b0'" />
    </v-dialog>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import { VueTyper } from "vue-typer";

const meta = {
  title: "Главная",
  description: "Поможем получить финансирова­ние ваших неоплачен­ных счетов",
};

export default {
  components: {
    Carousel,
    Slide,
    VueTyper,
    "v-dialog": () => import("@/components/Dialog"),
    "v-form": () => import("@/components/Form"),
    "v-form-block": () => import("@/components/FormBlock"),
    "v-card": () => import("@/components/Card"),
    "v-card-review": () => import("@/components/CardReview"),
    "v-hero-img": () => import("@/components/HeroImg"),
    "v-scroll-anim": () => import("@/components/ScrollAnim"),
    "v-bill-to-form": () => import("@/components/BillToForm"),
    "v-benefits": () => import("@/components/Benefits"),
    "v-graph": () => import("@/components/Graph"),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "description",
        content: meta.description,
      },
      {
        name: "og:description",
        content: meta.description,
      },
    ],
  },
  data() {
    return {
      config1: {
        scrollId: "scroll-bill-to-form",
        texts: [
          {
            title: "Легко избавьтесь от кассового разрыва",
            text: "Вам не нужно открывать новые кредитные линии, пользоваться овердрафтом в банке или подстраиваться под жёсткие требования крупных факторов. EdiFin избавит от ожидания платежей по вашим поставкам.",
          },
          {
            title: "Отправьте запрос через EdiFin",
            text: "Заполните форму на сайте. Мы мгновенно передадим заявку нашим партнёрам-факторам. Без дополнительных комиссий, длительных проверок и лишней бюрократии с бумагами.",
          },
          {
            title: "Получите деньги",
            text: "Получите предложение и заключите соглашение с фактором. Начинайте работать. Пополните оборотный капитал вашей компании за счёт переуступки дебиторской задолженности в короткие сроки.",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.home {
  &__banner {
    margin: $space-120 0;

    @include mobile {
      margin: $space-64 0;
    }
  }

  &__carousel {
    margin-bottom: $space-80;

    @include mobile {
      margin-bottom: $space-64;
    }

    &-title {
      text-align: center;

      @include mobile {
        margin-bottom: $space-32;
        text-align: left;
      }
    }

    &-btn {
      text-align: center;
    }

    @include mobile {
      .VueCarousel-wrapper {
        overflow: inherit;
      }

      .VueCarousel-inner {
        padding: $space-0 0 $space-32;
      }

      .VueCarousel-slide {
        > div {
          padding-left: 8px;
          padding-right: 8px;
        }

        &:first-child {
          > div {
            padding-left: 0;
          }
        }

        &:last-child {
          > div {
            padding-right: 0;
          }
        }
      }

      .VueCarousel-navigation-button {
        display: none;
      }
    }

    .VueCarousel-inner .VueCarousel-slide > div {
      cursor: initial;
      min-height: 100%;
      height: 100%;
    }
  }

  &__bill-anim {
    padding: 250px 0 400px;
    background-color: $teal-700;

    @include mobile {
      padding: $space-64 0;
    }
  }

  &__benefits {
    margin: $space-120 0;

    @include mobile {
      margin: $space-64 0;
    }
  }
}

.anim-mobile {
  color: $white;

  &__block:not(:last-child) {
    margin-bottom: $space-80;
  }

  &__img {
    position: relative;
    margin-bottom: $space-24;
    text-align: center;
  }

  &__text {
    h2 {
      margin-bottom: $space-24;
    }

    p {
    }
  }
}

.banner {
  &__container {
    margin: 0 -56px;
    padding: $space-80;
    border-radius: $radius-lg;
    background: $bg-teal-to-green;
    color: $white;

    @include mobile {
      margin: 0;
      padding: $space-48 $space-24 $space-56;
    }
  }

  &__title {
    @include tablet {
      margin-bottom: $space-56;
    }

    span {
      display: inline-block;
      position: relative;
      z-index: 10;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background-color: $green-500;
        z-index: -1;

        @include mobile {
          left: 0;
          height: 12px;
        }
      }
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;
    }
  }

  &__col {
    width: 28%;
    display: flex;
    flex-direction: column;

    @include tablet {
      width: 100%;
      margin-bottom: $space-40;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__subtitle {
    margin-bottom: $space-32;

    @include tablet {
      margin-bottom: $space-24;
    }
  }

  &__text {
    margin-top: 0;
  }

  &-logo {
    @include tablet {
      max-width: 240px;
    }

    @include mobile {
      max-width: 240px;
    }
  }
}
</style>
